@import "./general/general-style";
@import "./Abstracts/main";

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  text-decoration: none;
}
html {
  font-family: "Roboto", sans-serif;
  font-size: 62.5%;
}

body {
  line-height: 1.7;
  box-sizing: border-box;
  // font-family: "Roboto", sans-serif;
  // color: #202020;

  //background-color: $secondaryBackgroundColor;
  // font-smooth: always;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  background-color: $AppBackgroundColor;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal2-popup {
  
  font-size: 1.4rem!important;

}
//image modal
.image__modal__active{
  overflow-y: hidden;
}