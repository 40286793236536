@keyframes Zoom-out{
    0%{
        transform: scale(.5);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes slide-up{
    0%{
        margin-top: 0;
        opacity: 0;
    }
    100%{
        margin-top: -100px;
        opacity: 1;
    }
}
@media(min-width: 768px){
   
    @keyframes slide-up{
        0%{
            margin-top: 0;
            opacity: 0;
        }
        100%{
            margin-top: -300px;
            opacity: 1;
        }
    }

}
@keyframes slide-right{
    0%{
        margin-left: -200px;
        opacity: 0;
    }
    100%{
        margin-left: 0;
        opacity: 1;
    }
}