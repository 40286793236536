html,
body{
    // font-size: 14px;
    line-height: 1;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}
h1{
    font-size: 34px;
    line-height: 1.5;
    font-weight: 600;
}
h2{
    font-size: 28px;
    line-height: 1.5;
}
h3{
    font-size: 24px;
    line-height: 1.5;
}
h4{
    font-size: 18px;
    line-height: 1.5;
}
h5{
    font-size: 16px;
    line-height: 1.5;
}
h6{
    font-size: 14px;
    line-height: 1.5;
}
p{
    font-size: 14px;
    line-height: 1.5;
}
ul{
    li{
        line-height: 1.5;
    }
}

@media (min-width: 768px){
    h1{
        font-size: 60px;
        line-height: 1.5;
        font-weight: 600;
    }
    h2{
        font-size: 36px;
        line-height: 1.5;
    }
}